import React from 'react'
import { graphql } from 'gatsby'

import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Sponsors, { Testimonials } from 'Section/Sponsors'
import { mapEdgesToNodes } from 'libs/helpers'
import Container from 'Primitive/Container'
import getTranslation from '../../src/utility/language-picker'
import { useTranslation } from 'react-i18next'

export const query = graphql`
  query Clients {
    page: allSanityClientPage {
      edges {
        node {
          sponsorList {
            title {
              value
              _key
            }
            _rawDescription
            sponsors {
              title {
                value
                _key
              }
              _rawDescription
              sponsors {
                isFeatured
                name
                url
                quoteHeading
                quoteBody {
                  value
                  _key
                }
                image {
                  asset {
                    url
                    _id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Clients = (props) => {
  const { t } = useTranslation()
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = (data || {}).page ? mapEdgesToNodes(data.page) : []
  let { sponsorList } = page[0]

  if (!page) {
    throw new Error(
      'Missing "Clients" page data. Open the studio at http://localhost:3333 and add "Clients" page data and restart the development server.'
    )
  }

  function filterLanguage(obj) {
    if (!obj) {
      return obj
    }

    const translatedObj = getTranslation(obj)

    if (translatedObj.sponsors) {
      translatedObj.sponsors = translatedObj.sponsors.map((sponsor) =>
        filterLanguage(sponsor)
      )
    }

    return translatedObj
  }

  sponsorList = filterLanguage(sponsorList)

  const allSponsors =
    sponsorList &&
    sponsorList.sponsors.map((sponsorCat) => sponsorCat.sponsors).flat()

  return (
    <Layout shouldHaveSpaceForNav disableFooterOverlay>
      <SEO title={t('clients.pageTitle')} slug="/clients" />
      <Container spacious>
        {sponsorList && <Sponsors {...sponsorList} />}
        {sponsorList && (
          <Testimonials
            title={t('clients.testimonials')}
            sponsors={allSponsors}
          />
        )}
      </Container>
    </Layout>
  )
}

export default Clients
